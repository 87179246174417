import axios from "axios";

export default {
  state: {
    formData: {},
    instance: axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46YWRtaW4=",
      },
    }),
    shema: {
      id: 0,
    },
    // schemaImage: null,
  },
  getters: {
    getInstanceAxios: (state) => state.instance,
    getFormData: (state) => state.formData,
    getSchema: (state) => state.shema,
    // getSchemaImage: (state) => state.schemaImage,
  },
  mutations: {
    updateFormData(state, value) {
      state.formData = Object.assign({}, value);
    },
    deleteSpace(state, itemIndex) {
      state.spacesArray.splice(itemIndex, 1);
    },
    setSchema(state, value) {
      state.shema = value;
    },
    // setSchemaImage(state, value) {
    //   state.schemaImage = value;
    // },
  },
  actions: {
    async deleteItem({ getters }, value) {
      // const path = `${value.type}s/${value.id}`;
      // try {
      //   await getters.getInstanceAxios.delete(path);
      // } catch (error) {
      //   console.error("deleted error = ", error);
      // }
      return await getters.getInstanceAxios.delete(
        `${value.type}s/${value.id}`
      );
    },
    //Requsets for Region----------------------//
    async getRegion({ getters }, id) {
      try {
        const path = `regions/${id}`;
        return await getters.getInstanceAxios.get(path);
      } catch (error) {
        console.error("get region error = ", error);
      }
    },

    //Request for Scheme ---------------------//
    // async getScheme({ getters, commit }, id) {
    //   try {
    //     const path = `schemas/${id}`;
    //     await getters.getInstanceAxios.get(path).then((res) => {
    //       commit("setSchema", res.data.data[0]);
    //     });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    async uploadImageSchemas(_, payload) {
      try {
        const path = `schemas/${payload.get("id")}/upload-file`;
        await axios.post(path, payload, {
          baseURL: process.env.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic YWRtaW46YWRtaW4=",
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getImage({ commit }, id) {
      try {
        const path = `files/${id}`;
        await axios
          .get(path, {
            baseURL: process.env.VUE_APP_BASE_URL,
            headers: {
              Authorization: "Basic YWRtaW46YWRtaW4=",
            },
            responseType: "blob",
          })
          .then((response) => {
            commit(
              "setSchemaImage",
              window.URL.createObjectURL(new Blob([response.data]))
            );
          })
          .catch(() => {
            commit("setSchemaImage", null);
          });
      } catch (err) {
        commit("setSchemaImage", null);
      }
    },
  },
};
