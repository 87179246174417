import axios from "axios";

const myInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  actions: {
    async createRegion(_, data) {
      const path = `regions`;
      return await myInstance.post(path, data);
    },
    async fetchRegion(_, id) {
      const path = `regions/${id}`;
      return await myInstance.get(path);
    },
    async updateRegion(_, data) {
      const path = `regions/${data.id}`;
      return await myInstance.post(path, data.payload);
    },
  },
};
