<template>
  <v-app class="">
    <v-main class="lightGray">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>
