export const alertModule = {
  state: {
    info: "",
    isShow: false,
  },
  getters: {
    getInfoAlert: (state) => state.info,
    getIsShowAlert: (state) => state.isShow,
  },
  mutations: {
    setInfo(state, text) {
      state.info = text;
    },
    setShow(state, isShow) {
      state.isShow = isShow;
    },
  },
  actions: {
    callAlert({ commit }, text) {
      commit("setInfo", text);
      commit("setShow", true);
    },
    closeAlert({ commit }) {
      commit("setInfo", "");
      commit("setShow", false);
    },
  },
};
