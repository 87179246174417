// import axios from "axios";

export default {
	state: {
		polygons: new Array(),
		polygon: {
			polygonVertices: new Array(),
			devices: new Array(),
		},
		spacesArray: new Array(),
	},
	getters: {
		getPolygon(state) {
			return state.polygon;
		},
		getPolygons(state) {
			return state.polygons;
		},
		getSpacesArray: (state) => state.spacesArray
	},
	mutations: {
		updateArrayPolygons(state, value) {
			state.polygons.push(Object.assign({}, value));
		},
		updatePolygon(state, value) {
			state.polygon.polygonVertices = [...value];
		},
		setSpacesArray(state, value) {
			state.spacesArray = [...value];
		}
	},
	actions: {
		handler: () => { },
	}
}