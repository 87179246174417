import axios from "axios";
import { client } from "../api";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    profilesDataArray: [],
    profile: Object,
    spaceProfilesDataArray: [],
  },
  getters: {
    getProfilesDataArray: (state) => state.profilesDataArray,
    getProfile: (state) => state.profile,
    getSpaceProfiles: (state) => state.spaceProfilesDataArray,
  },
  mutations: {
    SET_PROFILES_ARRAY(state, value) {
      state.profilesDataArray = [...value];
    },
    SET_PROFILE(state, value) {
      state.profile = value;
    },
    SET_SPACE_PROFILES_ARRAY(state, value) {
      state.spaceProfilesDataArray = value;
    },
  },
  actions: {
    //-------------------device profiles-----------------------//
    async fetchProfiles({ commit }) {
      return await instance
        .get("device-profiles")
        .then((response) => commit("SET_PROFILES_ARRAY", response.data.data));
    },
    async createProfile(_, payload) {
      return await instance.post("device-profiles", payload);
    },
    async updateProfile(_, payload) {
      return await instance.post(`device-profiles/${payload.id}`, payload);
    },

    async deleteProfile(_, id) {
      try {
        const path = `device-profiles/${id}`;
        await instance.delete(path);
      } catch (err) {
        console.log("Error profile DELETE");
      }
    },

    //spaces profiles
    fetchSpaceProfile: async ({ getters, commit }) => {
      try {
        const path = "space-profiles";
        const response = await getters.getInstanceAxios.get(path);
        commit("SET_SPACE_PROFILES_ARRAY", response.data.data);
        //
      } catch (err) {
        console.error("get space profiles", err);
      }
    },
    async updateSpaceProfile(_, payload) {
      return await client.post(`space-profiles/${payload.id}`, payload);
    },
    async createSpaceProfile(_, payload) {
      return await client.post("space-profiles", payload);
    },

    async deleteSpaceProfile({ getters }, id) {
      try {
        const path = `space-profiles/${id}`;
        await getters.getInstanceAxios.delete(path);
      } catch (err) {
        console.log("delete space profile", err);
      }
    },

    async fetchSProfile({ getters }, id) {
      const path = `space-profiles/${id}`;
      return getters.getInstanceAxios.get(path);
    },
  },
};
