import { client } from "../api";

export default {
  state: {
    DataTable: [],
    RangeDate: [],
    SpaceLocations: [],
  },
  getters: {
    getReportTable: (state) => state.DataTable,
    getRangeTime: (state) => state.RangeDate,
    getSpaceLocations: (state) => state.SpaceLocations,
  },
  mutations: {
    SET_REPORT_TABLE(state, value) {
      state.DataTable = [...value];
    },
    SET_RANGE_TIME(state, value) {
      let arr = [];
      let start = null;
      let end = null;
      if (value.length === 1) {
        state.RangeDate = value;
        return;
      }
      if (new Date(value[0]) < new Date(value[1])) {
        start = value[0];
        end = value[1];
      } else {
        start = value[1];
        end = value[0];
      }

      for (
        let dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        const dateVal = new Date(dt).toISOString().split("T")[0];
        arr.push(dateVal);
      }
      state.RangeDate = arr;
    },
    SET_SPACE_LOCATIONS(state, value) {
      state.SpaceLocations = value;
    },
  },
  actions: {
    async fetchAllLocation({ commit }) {
      return client.get("/spaces/locations").then((res) => {
        commit("SET_SPACE_LOCATIONS", res.data.data);
      });
    },
    async fetchSummaryReport({ commit }, payload) {
      return client.post("/report/sum", payload).then((res) => {
        commit("SET_REPORT_TABLE", res.data.data);
      });
    },
    async fetchAverageReport({ commit }, payload) {
      return client.post("/report/average", payload).then((res) => {
        commit("SET_REPORT_TABLE", res.data.data);
      });
    },
    async fetchMeetings({ commit }, payload) {
      return client.post("/report/meetings", payload).then((res) => {
        commit("SET_REPORT_TABLE", res.data.data);
      });
    },
    async fetchCancelledMeetings({ commit }, payload) {
      return client.post("/report/cancelled", payload).then((res) => {
        commit("SET_REPORT_TABLE", res.data.data);
      });
    },
    async fetchBookingForSpace({ commit }, payload) {
      return client
        .post("/report/sum", payload, { params: { separate: true } })
        .then((res) => {
          commit("SET_REPORT_TABLE", res.data.data);
        });
    },
  },
};
