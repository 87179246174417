import { client } from "../../api";

export default {
  actions: {
    async fetchDevice() {},
    async createDevice(_, data) {
      return await client.post(`devices`, data);
    },
    async updateDevice(_, data) {
      return await client.post(`devices/${data.id}`, data.payload);
    },
  },
};
