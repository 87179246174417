import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routers = [
  {
    path: "/login",
    name: "login",
    component: Login,
    props: { name: "Авторизация" },
    meta: {
      layout: "auth-layout",
      isAuth: false,
    },
  },
  {
    path: "/",
    redirect: (to) => {
      return { path: "/placement", query: { q: to.params.searchText } };
    },
  },
  {
    path: "/placement",
    name: "placement",
    component: () => import("@/views/Placement"),
    meta: {},
  },
  {
    path: "/devices",
    name: "devices",
    component: () => import("@/views/Devices"),
  },
  {
    path: "/devices/:id",
    name: "devicesDetails",
    component: () => import("@/views/DevicesDetails"),
    meta: {},
  },
  {
    path: "/profiles",
    name: "profiles",
    component: () => import("@/views/Profiles"),
    meta: {},
  },
  {
    path: "/statistics/number-meetings",
    name: "NumberMeetings",
    component: () => import("@/views/Reports/NumberMeetings"),
    meta: {},
  },
  {
    path: "/statistics/average-day-time-booking",
    name: "AverageDayTimeBooking",
    component: () => import("@/views/Reports/AverageDayTimeBooking"),
    meta: {},
  },
  {
    path: "/statistics/cancled-booking",
    name: "CancledBooking",
    component: () => import("@/views/Reports/CancledBooking"),
    meta: {},
  },
  {
    path: "/statistics/summary-time-booking",
    name: "SummaryTimeBooking",
    component: () => import("@/views/Reports/SummaryTimeBooking"),
    meta: {},
  },
  {
    path: "/statistics/booking-for-space",
    name: "BookingForSpace",
    component: () => import("@/views/Reports/BookingForSpace"),
    meta: {},
  },
  {
    path: "/profile-user",
    name: "profileUser",
    component: () => import("@/views/ProfileUser/ProfileUser"),
    meta: {},
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/AdminDashboard/AdminDashboard"),
    meta: {},
  },
];

const router = new VueRouter({
  routes: routers,
  mode: "history",
  // base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const adminPages = ["/dashboard"];

  const roleRequired = adminPages.includes(to.path);
  const authRequired = !publicPages.includes(to.path);
  //
  // const loggedIn = localStorage.getItem("user");
  const loggedIn = localStorage.getItem("user");
  let role = null;
  if (loggedIn)
    role = JSON.parse(localStorage.getItem("user")).roles.includes(
      "ROLE_ADMIN"
    );
  if (authRequired && !loggedIn) return next("login");
  if (roleRequired && authRequired && loggedIn && !role) return next("/");

  next();
});

export default router;
