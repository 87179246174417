// import router from "@/router";
import AuthService from "../../services/auth.service";

export const authentication = {
  state: {
    user: null,
    status: {},
  },
  getters: {
    getCurrentUser: (state) => state.user,
  },
  mutations: {
    setUserData(state, data) {
      state.user = data;
    },

    loginSucces(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = true;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      console.log("asdasd");
    },
  },
  actions: {
    async login({ commit }, user) {
      return AuthService.login(user).then((user) => {
        commit("loginSucces", user);
        return Promise.resolve(user);
      });
    },
    async logout({ commit }) {
      await AuthService.logout();
      commit("setUserData", null);
      // router.push({ name: "login" });
      location.reload(true);
    },
    async refreshToken({ commit }, user) {
      AuthService.refreshToken(user.refresh_token).then((user) => {
        commit("loginSucces", user);
      });
    },
  },
};
