import { client } from "../store/api";

const AuthService = {
  login,
  logout,
  refreshToken,
};

async function login(user) {
  return await client.post("/auth/login", user).then((res) => {
    // console.log("login", res.data);
    if (res.data.access_token) {
      localStorage.setItem("user", JSON.stringify(res.data));
    }
    return res.data;
  });
}

async function logout() {
  return await client
    .post("/auth/logout")
    .then(() => {})
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      localStorage.removeItem("user");
    });
}

async function refreshToken(refresh_token) {
  return await client.post("/auth/login", { refresh_token }).then((res) => {
    if (res.data.access_token) {
      localStorage.setItem("user", JSON.stringify(res.data));
    }
    return res.data;
  });
}

export default AuthService;
