import axios from "axios";
import { client } from "../../api";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    listAllSpaces: [],
  },
  getters: {
    getListAllSpaces: (state) => state.listAllSpaces,
  },
  mutations: {
    SET_LIST_ALL_SPACES(state, value) {
      state.listAllSpaces = value;
    },
  },
  actions: {
    async fetchSpace(_, id) {
      const path = `spaces/${id}`;
      return await instance.get(path);
    },
    async createSpace(_, data) {
      const path = `spaces`;
      return await instance.post(path, data);
    },
    async updateSpace(_, data) {
      const path = `spaces/${data.id}`;
      return await instance.post(path, data.payload);
    },
    async fetchSpaces({ commit }) {
      return client.get("/spaces").then((res) => {
        commit("SET_LIST_ALL_SPACES", res.data.data);
      });
    },
  },
};
