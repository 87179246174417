<template>
  <v-app>
    <header-menu />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import HeaderMenu from "../components/HeaderMenu.vue";
export default {
  name: "DefaultLayout",
  components: {
    HeaderMenu,
  },
};
</script>
