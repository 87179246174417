<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card elevation="0" class="card">
            <v-toolbar elevation="0">
              <v-toolbar-title>
                Вход
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div v-if="errors" class="mb-3 red--text">
                {{ errors[0] }}
              </div>
              <v-form>
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="base-text-field mb-3"
                  prepend-icon="mdi-account"
                  name="login"
                  label="Логин"
                  type="text"
                  v-model="username"
                />
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="base-text-field"
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Пароль"
                  type="password"
                  v-model="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                tile
                elevation="0"
                color="green white--text"
                @click="submit"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  data() {
    const username = "";
    const password = "";
    const errors = [];
    return {
      username,
      password,
      errors,
    };
  },
  methods: {
    submit() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },
  },
  validation() {},
};
</script>

<style scoped>
.card {
  border: 1px solid darkgray !important;
}
</style>
