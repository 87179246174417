<template>
  <div class="d-flex align-center header-menu">
    <v-tabs background-color="lightGray2" slider-color="lightGray2">
      <v-menu
        v-for="tab in menu"
        :key="tab.pathName"
        :close-on-click="true"
        :close-on-content-click="true"
        open-on-hover
        offset-y
        :close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-bind="attrs"
            v-on="on"
            :to="{ name: tab.pathName }"
            active-class="header-menu__item-active"
            v-if="
              tab.protected
                ? tab.protected && currentUser.roles.includes(requier_user)
                : true
            "
          >
            <span class="header-menu__item">
              {{ tab.label }}
            </span>
          </v-tab>
        </template>
        <v-list v-if="!!tab.subMenu" dense>
          <v-list-item-group>
            <v-list-item v-for="subTab in tab.subMenu" :key="subTab.pathName">
              <v-list-item-title>
                <router-link
                  class="sub-menu__link"
                  :to="{ name: subTab.pathName }"
                >
                  {{ subTab.label }}
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-spacer />
    <div>
      <setting-menu />
    </div>
  </div>
</template>

<script>
import { menu } from "@/utils/header-nav";
import SettingMenu from "./SettingMenu.vue";
import getRequireUser from "@/utils/getRequireUser";

export default {
  components: { SettingMenu },
  data() {
    const requier_user = null;
    return {
      requier_user,
      menu,
    };
  },
  created() {
    this.requier_user = getRequireUser();
  },
  mounted() {},
  computed: {
    currentUser() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
};
</script>

<style scoped lang="scss">
.header-menu {
  background-color: var(--color-lightGray-2);
  &__item-active {
    font-weight: 700;
  }
}
.sub-menu {
  &__link {
    color: inherit;
    text-decoration: none;
  }
}
</style>
