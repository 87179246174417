import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  actions: {
    async fetchBuilding(_, id) {
      const path = `buildings/${id}`;
      return await instance.get(path);
    },
    async createBuilding(_, data) {
      const path = "buildings";
      return await instance.post(path, data);
    },
    async updateBuilding(_, data) {
      const path = `buildings/${data.id}`;
      return await instance.post(path, data.payload);
    },
  },
};
