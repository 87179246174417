import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        blue: "#377BB5",
        lightBlue: "#7da9cf",
        green: "#5FB760",
        greenNew: "#4CAF50",
        lightGray: "#F8F8F8",
        lightGray2: "#E9E9E9",
        darkGray: "#C4C4C4",
        white: "#fefefe",
      },
    },
  },
});
