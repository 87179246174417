import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    listEquipment: new Array(),
    equipment: null,
  },
  getters: {
    getListEquipments: (state) => state.listEquipment,
  },
  mutations: {
    setListEquipments(state, data) {
      state.listEquipment = data;
    },
    SET_EQUIPMENT(state, newEquipment) {
      state.equipment = newEquipment;
    },
  },
  actions: {
    async fetchListEquipments({ commit }) {
      const path = `equipment`;
      return await instance
        .get(path)
        .then((response) => commit("setListEquipments", response.data.data));
    },
  },
};
