import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": `application/json`,
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    schemaImage: null,
    hashedImages: {},
  },
  getters: {
    getSchemaImage: (state) => state.schemaImage,
    getHashedImages: (state) => state.hashedImages,
  },
  mutations: {
    setSchemaImage(state, file) {
      state.schemaImage = file;
    },
    addSchemaHash(state, data) {
      state.hashedImages[data.id] = data.file;
    },
  },
  actions: {
    // async fetchSchema(_, id) {
    //   const path = `schemas/${id}`;
    //   return await axios.get(path, {
    //     Authorization: "Basic YWRtaW46YWRtaW4=",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Basic YWRtaW46YWRtaW4=",
    //     },
    //   });
    // },
    async createSchema(_, data) {
      const path = `schemas`;
      return await instance.post(path, data);
    },
    async updateSchema(_, data) {
      const path = `schemas/${data.id}`;
      return await instance.post(path, data.payload);
    },
    async uploadSchemFile(_, data) {
      const path = `schemas/${data.id}/file`;
      return await instance.post(path, data.payload);
    },

    async fetchSchemaFile({ commit }, id) {
      return await axios
        .get(`schemas/${id}/file`, {
          baseURL: process.env.VUE_APP_BASE_URL,
          headers: {
            Authorization: "Basic YWRtaW46YWRtaW4=",
          },
          responseType: "blob",
        })
        .then((response) => {
          commit(
            "setSchemaImage",
            window.URL.createObjectURL(new Blob([response.data]))
          );
        })
        .catch(() => {
          commit("setSchemaImage", null);
        });
    },
  },
};
