import axios from "axios";
import router from "@/router";

const config = {
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // withCredentials: true,
  data: {},
};

export const client = axios.create(config);

client.interceptors.request.use(
  async (request) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (request.url.includes("login")) {
      //
    } else {
      //
      request.headers["Authorization"] = `Bearer ` + user.access_token;
    }
    return request;
  },
  (error) => {
    console.debug(error);
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  async (response) => response,
  async (error) => {
    console.log(error);
    if (error.response.status) {
      if (!error.response.status) return;
      if (error.response.status === 401) {
        // if (error.response.config.url.includes("login")) {
        //   return Promise.reject("Неверный логин или пароль");
        // }
      }
      if (error.response.status === 401) {
        const user = JSON.parse(localStorage.getItem("user"));
        console.log("401!", user);
        if (user) {
          this.$store
            .dispatch("refreshToken", user.rerefresh_token)
            .then(() => {
              location.reload(true);
              return;
            })
            .catch(() => {
              //
            });
        }
        router.push({ name: "login" });
      }
    }

    return Promise.reject(error);
  }
);
