export const menu = [
  {
    label: "Размещение",
    pathName: "placement",
  },
  {
    label: "Устройства",
    pathName: "devices",
  },
  {
    label: "Профили",
    pathName: "profiles",
  },
  {
    label: "Отчёты",
    pathName: "SummaryTimeBooking",
    subMenu: [
      {
        label: "Суммарное время бронирования пространства",
        pathName: "SummaryTimeBooking",
      },
      {
        label: "Среднее дневное время бронирования",
        pathName: "AverageDayTimeBooking",
      },
      {
        label: "Количество встреч",
        pathName: "NumberMeetings",
      },
      {
        label: "Отмененные бронирования",
        pathName: "CancledBooking",
      },
      {
        label: " Бронирования для пространств",
        pathName: "BookingForSpace",
      },
    ],
  },
  {
    label: "Администрирование",
    pathName: "dashboard",
    protected: true,
  },
];
