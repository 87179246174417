import axios from "axios";
import { client } from "../api";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    tree: null,
    activeNode: {},
    timeZone: [
      {
        value: "UTC+02:00",
        name: "Калининград UTC+2",
      },
      {
        value: "UTC+03:00",
        name: "Москва UTC+3",
      },
      {
        value: "UTC+04:00",
        name: "Самара UTC+4",
      },
      {
        value: "UTC+05:00",
        name: "Екатеринбург UTC+5",
      },
      {
        value: "UTC+06:00",
        name: "Омск UTC+6",
      },
      {
        value: "UTC+07:00",
        name: "Красноярск UTC+7",
      },
      {
        value: "UTC+08:00",
        name: "Иркутск UTC+8",
      },
      {
        value: "UTC+09:00",
        name: "Якутия UTC+9",
      },
      {
        value: "UTC+10:00",
        name: "Владивосток UTC+10",
      },
      {
        value: "UTC+11:00",
        name: "Магадан UTC+11",
      },
      {
        value: "UTC+12:00",
        name: "Камчатка UTC+12",
      },
    ],
    listBooking: [],
    activeSchema: {},
  },
  getters: {
    getTree: (state) => state.tree,
    getActiveNode: (state) => state.activeNode,
    getTimeZone: (state) => state.timeZone,
    getListBooking: (state) => state.listBooking,
    getActiveSchema: (state) => state.activeSchema,
  },
  mutations: {
    SET_TREE(state, value) {
      state.tree = value;
    },
    SET_ACTIVE_NODE(state, value) {
      state.activeNode = value;
    },
    SET_LIST_BOOKING(state, value) {
      state.listBooking = value;
    },
    SET_ACTIVE_SCHEMA(state, value) {
      state.activeSchema = value;
    },
  },
  actions: {
    async fetchTree({ commit }) {
      return await client.get("regions/tree").then((response) => {
        commit("SET_TREE", response.data.data);
      });
    },
    async fetchSpaceBooking({ commit }, data) {
      try {
        let path = null;
        path = `spaces/${data.id}/reservations/${data.date}`;
        const response = await instance.get(path);
        commit("SET_LIST_BOOKING", response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSchema({ commit }, id) {
      return await client
        .get(`schemas/${id}`)
        .then((res) => {
          commit("SET_ACTIVE_SCHEMA", res.data.data);
        })
        .catch(() => commit("SET_ACTIVE_SCHEMA", {}));
    },
  },
};
