<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="true"
    open-on-hover
    offset-y
    :close-delay="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="button-cog">
        <v-icon>
          mdi-cog
        </v-icon>
      </div>
    </template>
    <v-list dense tile>
      <!--  :to="{ name: item.url_name }" -->
      <v-list-item
        v-for="item in listSettings"
        :key="item.name"
        @click.prevent="item.action()"
      >
        <v-list-item-content class="text-left">
          <v-list-item-title> {{ item.label }} </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    const selected = null;
    const listSettings = [
      {
        name: "profileUser",
        label: "Профиль",
        icon: "mdi-account",
        url_name: "profileUser",
        action: () => {
          this.clear();
        },
      },
      {
        name: "exit",
        label: "Выход",
        icon: "mdi-logout",
        url_name: "login",
        action: () => {
          this.$store.dispatch("logout");
        },
      },
    ];
    return {
      listSettings,
      selected,
    };
  },
  methods: {
    clear() {},
  },
};
</script>

<style lang="scss" scoped>
.button-cog {
  padding: 0 1em 0 1em;
}
</style>
