var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center header-menu"},[_c('v-tabs',{attrs:{"background-color":"lightGray2","slider-color":"lightGray2"}},_vm._l((_vm.menu),function(tab){return _c('v-menu',{key:tab.pathName,attrs:{"close-on-click":true,"close-on-content-click":true,"open-on-hover":"","offset-y":"","close-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
            tab.protected
              ? tab.protected && _vm.currentUser.roles.includes(_vm.requier_user)
              : true
          )?_c('v-tab',_vm._g(_vm._b({attrs:{"to":{ name: tab.pathName },"active-class":"header-menu__item-active"}},'v-tab',attrs,false),on),[_c('span',{staticClass:"header-menu__item"},[_vm._v(" "+_vm._s(tab.label)+" ")])]):_vm._e()]}}],null,true)},[(!!tab.subMenu)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((tab.subMenu),function(subTab){return _c('v-list-item',{key:subTab.pathName},[_c('v-list-item-title',[_c('router-link',{staticClass:"sub-menu__link",attrs:{"to":{ name: subTab.pathName }}},[_vm._v(" "+_vm._s(subTab.label)+" ")])],1)],1)}),1)],1):_vm._e()],1)}),1),_c('v-spacer'),_c('div',[_c('setting-menu')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }