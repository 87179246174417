<template>
  <div id="app">
    <component :is="layout">
      <v-container fluid class="main-page-container">
        <router-view v-slot="{ Component, route }">
          <transition name="slide">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
      </v-container>
    </component>
  </div>
</template>

<script>
import "./style/fonts.css";
export default {
  name: "App",
  data: () => ({}),
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
};
</script>

<style lang="scss">
@import "./style/main";
@import "./style/inputs/textField";
@import "./style/inputs/autocomplete";
@import "./style/inputs/select";
@import "./style/tables/baseTable";
@import "./style/tabs/baseTabs";
@import "./style/forms/baseForm.scss";
@import "./style/inputs/checkbox.scss";
</style>

<style scoped lang="scss">
.main-page-container {
  padding: 0;
}
</style>
