import Vue from "vue";
import Vuex from "vuex";

import polygon from "./modules/polygon";
import formData from "./modules/formData";
import profiles from "./modules/profiles";
import devices from "./modules/devices";
import reports from "./modules/reports";

//refactoring
import placement from "./modules/placement";
import placementBuilding from "./modules/placementForms/building";
import placementDevice from "./modules/placementForms/device";
import placementRegion from "./modules/placementForms/region";
import placementScheme from "./modules/placementForms/scheme";
import placementSpace from "./modules/placementForms/space";
import placementEquipment from "./modules/placementForms/equipment";

import { authentication } from "./modules/authentication.module";
import {
  adminDashboardUsers,
  adminDashboardRoles,
} from "./modules/adminDashboard.module";
import { alertModule } from "./modules/alert.module";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    layout: "default-layout",
  },
  getters: {
    layout(state) {
      return state.layout;
    },
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
  },
  actions: {},
  modules: {
    polygon,
    formData,
    profiles,
    devices,
    reports,

    //placementQuery
    placement,
    placementBuilding,
    placementDevice,
    placementRegion,
    placementScheme,
    placementSpace,
    placementEquipment,

    //
    authentication,
    adminDashboardUsers,
    adminDashboardRoles,
    //
    alertModule,
  },
});
