import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

import DefaultLayout from "./layouts/DefaultLayout.vue";
import AuthLayout from "./layouts/AuthLayout.vue";

Vue.config.productionTip = false;

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export const eventBus = new Vue();

Vue.component("default-layout", DefaultLayout);
Vue.component("auth-layout", AuthLayout);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
