import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  },
});

export default {
  state: {
    listDevices: new Array(),
    device: null,
  },
  getters: {
    getListDevices: (state) => state.listDevices,
    getCurrentDevice: (state) => state.device,
  },
  mutations: {
    setListDevices(state, data) {
      state.listDevices = data;
    },
    SET_DEVICE(state, newDevice) {
      state.device = newDevice;
    },
  },
  actions: {
    async fetchListDevices({ commit }) {
      try {
        let path = `devices`;
        const { data } = await instance.get(path);
        commit("setListDevices", data.data);
      } catch (error) {
        console.error("Fetch devices", error);
      }
    },
    async fetchDevice({ commit }, payload) {
      try {
        let path = `devices/${payload.id}`;
        let response = await instance.get(path);
        commit("SET_DEVICE", response.data);
      } catch (error) {
        console.error("Fetch device", error);
      }
    },
  },
};
