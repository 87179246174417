import { client } from "../api";

export const adminDashboardUsers = {
  state: {
    usersAccounts: [],
    editedUser: {},
    domainUsers: [],
  },
  getters: {
    getUsersAccountsList: (state) => state.usersAccounts,
    getEditedUser: (state) => state.editedUser,
    getDomainUsers: (state) => state.domainUsers,
  },
  mutations: {
    setUsersAccountsList(state, data) {
      state.usersAccounts = data;
    },
    setEditedUser(state, data) {
      state.editedUser = data;
    },
    setDomainUsers(state, data) {
      state.domainUsers = data;
    },
  },
  actions: {
    async getAllUsersAccounts({ commit }) {
      // commit("setUsersAccountsList", tmp_users);
      return await client.get("/users").then((res) => {
        commit("setUsersAccountsList", res.data.data);
      });
    },
    async fetchDomainUsersCN({ commit }) {
      return await client.get("/users/ad").then((res) => {
        commit("setDomainUsers", res.data.data);
      });
    },
    async createNewUserLocale({ dispatch }, payload) {
      return await client.post("/users/local", payload).then(() => {
        dispatch("getAllUsersAccounts");
      });
    },
    async createNewUserDomain({ dispatch }, payload) {
      return await client.post("/users/domain", payload).then(() => {
        dispatch("getAllUsersAccounts");
      });
    },
    async updateUser({ dispatch }, payload) {
      return await client.post(`/users/${payload.id}`, payload).then(() => {
        dispatch("getAllUsersAccounts");
      });
    },
    async updateUserRoles({ dispatch }, payload) {
      return await client
        .post(`/users/${payload.id}/role`, payload)
        .then(() => {
          dispatch("getAllUsersAccounts");
        });
    },
    async deleteUser({ dispatch }, id) {
      return await client.delete(`/users/${id}`).then(() => {
        dispatch("getAllUsersAccounts");
      });
    },
    async searchUser({ commit }, payload) {
      return await client
        .get("", payload)
        .then((res) => commit("setUsersAccountsList", res.data));
    },
  },
};

export const adminDashboardRoles = {
  state: {
    roles: [],
    permissions: [],
  },
  getters: {
    getRolesList: (state) => state.roles,
    getPermissionsList: (state) => state.permissions,
  },
  mutations: {
    setRoles(state, data) {
      state.roles = data;
    },
    setPermissions(state, data) {
      state.permissions = data;
    },
  },
  actions: {
    async getAllRoles({ commit }) {
      // commit("setRoles", tmp_roles);
      return client.get("/authority/roles").then((res) => {
        commit("setRoles", res.data.data);
      });
    },
    async createRole({ dispatch }, payload) {
      return await client.post("/authority/roles", payload).then(() => {
        dispatch("getAllRoles");
      });
    },
    async updateRole({ dispatch }, payload) {
      return await client
        .post(`/authority/roles/${payload.id}`, payload)
        .then(() => {
          dispatch("getAllRoles");
        });
    },
    async deleteRole({ dispatch }, id) {
      return await client.delete(`/authority/roles/${id}`).then(() => {
        dispatch("getAllRoles");
      });
    },
    async getPersmissionsList({ commit }) {
      return await client.get("/authority/privileges").then((res) => {
        commit("setPermissions", res.data.data[0].privileges);
      });
    },
  },
};
